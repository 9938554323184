import React, { useEffect, useRef, useState } from 'react';
import {
	Box,
	Chip,
	FormControl,
	FormControlLabel,
	Grid,
	IconButton,
	Link,
	Modal,
	Radio,
	RadioGroup,
	Typography,
	useTheme,
} from '@mui/material';
import { useAppContext } from '../../AppContext/App.context';
import { IFilterModal } from '../../containers/Reports/IReports';
import { makeStyles } from '@mui/styles';
import CancelIcon from '@mui/icons-material/Cancel';
import ClearIcon from '@mui/icons-material/Clear';
import ToolTipOnText from '../Tooltip/Tooltip';
import FilterIcon from '../../assets/Images/filterIconSvg';
import Dropdown from '../Dropdown/Dropdown';
import Dimensions from './Dimensions';
import AlertDialog from '../Dialog/Dialog';
import MultiSelectDropdown from '../Dropdown/MultiSelectDropdown';
import useToast from '../Toast/hooks/useToast';
import Button from '../Button/Button';
import Input from '../Input/Input';
import './HeaderFilterModal.scss';

// Interface ------------------------------------------------------------
interface IFilterModalProps {
	filters: any;
	selectedFilters?: Object;
	onReset: Function;
	onSubmit: Function;
	showRadioFilter?: boolean;
	disableAndRelation?: boolean;
	hideOrTab?: boolean;
	isDisabled?: boolean;
	showSegments?: boolean;
	allItemsAreSelected?: string;
	selectAllText?: string;
	updateResetState?: any;
	disableMultiSelect?: boolean;
	type?: any;
	customParameterList?: any;
	showCustomType?: any;
	campaignTypeArr?: any;
	showExcludeItem?: any;
}
// -----------------------------------------------------------
const HeaderFilterModal: React.FC<IFilterModalProps> = (props: any) => {
	//Hooks and other constants------------------------------------------
	const theme: any = useTheme();
	const toaster: any = useToast();
	const [appData]: any = useAppContext();
	const { segmentsList } = appData;
	//-------------------------------------------------------------------

	// States -------------------------------------------------------
	const filterButtonHandler = () => setShowModal(true);
	const resetFilterRef: any = useRef();
	const deletedAttributes: any = useRef([]);
	const addedAttributes: any = useRef([]);
	const [handleOpenDialog, setShowModal] = useState(false);
	const [filters, setFilters]: any = useState<Array<IFilterModal>>([]);
	const [salesTouchRadio, setSalesTouchRadio]: any = useState('no');
	const [disablePointerEvents, setDisablePointerEvents]: any = useState(false);
	const [textAreaValCopy, setTextAreaValCopy] = useState<string>('');
	const [chipValues, setChipValues]: any = useState();
	const [chipsList, setChipsList]: any = useState([]);
	const [showChips, setShowChips]: any = useState(true);
	const [currentChipIndex, setCurrentChipIndex]: any = useState(-1);
	let [relationalOperator, setRelationalOperator]: any = useState(1);
	let [selectedFilters, setSelectedFilters]: any = useState(props.se);
	let [validationETM, setValidationETM]: any = useState(false);
	let [dimensionValidation, setDimensionValidation]: any = useState(-1);
	let [showFilterApplied, setShowFilterApplied]: any = useState(false);

	//---------------------------------------------------------------

	// UseEffects ----------------------------------------------------
	useEffect(() => {
		if (props.filters.length > 0) {
			// I use ID to create a selected filter component that will be passed on submit.
			if (!props.selectedFilters) {
				props.filters.map((value: any) => {
					selectedFilters[value.id] = '';
				});
				setSelectedFilters({ ...selectedFilters });
			}

			if (props.showSegments && segmentsList.length > 0) {
				const existingFilter = props.filters;
				const segmentFilter = {
					name: 'Segment Type',
					data: segmentsList.map((item: any) => ({
						name: item.segment,
						value: item.segment_id,
					})),
					type: 'dropdown',
					labelName: 'Segment Type',
					id: 'segment_id',
				};
				setFilters([segmentFilter, ...existingFilter]);
			} else {
				setSalesTouchRadio(props?.selectedFilters?.sales_touches);
				setFilters([...props.filters]);
			}
		}
	}, [props.filters]);

	// useEffect for filter Applied check --------------------
	useEffect(() => {
		if (props?.selectedFilters) {
			const defaultFilter: any = props?.onReset() || {};
			let tempFlag = false;
			Object.keys(props.selectedFilters).find((key: any) => {
				if (
					key === 'start_date' ||
					key === 'end_date' ||
					key === 'ad_account' ||
					key === 'sales_touches' ||
					key === 'source_type' ||
					key === 'bing_sites' ||
					key === 'google_site' ||
					key === 'filters' ||
					key === 'isReload' ||
					key === 'showTabularData' ||
					key === 'excludes' ||
					key === 'relational_operator'
				) {
					return false;
				}
				if (key === 'conditions' && props.selectedFilters[key]?.length > 0) {
					tempFlag = true;
					return true;
				}
				// else if(key === 'ad_account' && props.selectedFilters[key].length > 0){
				//   tempFlag = true;
				//   return true;
				// }
				if (
					props.selectedFilters[key] &&
					props.selectedFilters[key] !== defaultFilter[key] &&
					key !== 'conditions'
				) {
					tempFlag = true;
					return true;
				}
				tempFlag = false;
			});
			setShowFilterApplied(tempFlag);
			if (props.updateResetState) {
				props.updateResetState(tempFlag);
			}
			selectedFilters = props.selectedFilters;
			setSelectedFilters({ ...selectedFilters });
		}
	}, [props.selectedFilters]);

	//---------------------------------------------------------------

	//Constants ----------------------------------------------------
	const data = {
		fill: theme.palette.text.tertiary,
		width: '20px',
		height: '20px',
	};

	const useStyles: any = makeStyles((theme: any) => ({
		inputLabel: {
			color: `${theme.palette.primary.main} !important`,
		},
		focused: {
			color: `${theme.palette.primary.main} !important`,
		},
	}));
	const classes = useStyles();
	//-------------------------------------------------------------

	//Events and Functions --------------------------------------------------

	const extractName = (arr: any) => {
		return arr.map((item: any) => {
			return item.name;
		});
	};
	const handleCloseDialog = () => {
		if (props?.selectedFilters?.conditions?.length == 1) {
			props?.selectedFilters?.conditions.map((item: any) => {
				if (item.operator == '' || item.parameter == '' || item.value == '') {
					props.selectedFilters.conditions = [];
				}
			});
		}
		setSelectedFilters(props.selectedFilters);
		setShowModal(false);
	};
	const onConditionUpdated = (conditions: Object[], name: any) => {
		selectedFilters[name] = [...conditions];
	};
	const onReset = () => {
		// This should return default values as user can cancel reset as well
		chipsList.length = 0;
		setSelectedFilters({ ...props.onReset() });
		setDimensionValidation(-1);
		setValidationETM(false);
	};
	const submitFilters = () => {
		setValidationETM(true);
		showFilterApplied = false;
		setShowFilterApplied(showFilterApplied);
		let dimensionError = -1;
		selectedFilters.relational_operator = relationalOperator;
		selectedFilters.excludes = extractName(chipsList);
		selectedFilters?.conditions?.find((condition: any, dimensionIndex: any) => {
			if (!condition.operator) {
				dimensionError = dimensionIndex;
				return true;
			}
			// if (condition.type !== 1  && condition.type !== 0) {
			// 		dimensionError = dimensionIndex;
			// 		return true;
			// 	}
			if (!condition.parameter) {
				dimensionError = dimensionIndex;
				return true;
			}
			if (!condition.value) {
				dimensionError = dimensionIndex;
				return true;
			}
			// two time same code ???? delete this 10 oct 24----------------
			// if (!condition.value) {
			// 	dimensionError = dimensionIndex;
			// 	return true;
			// }
			showFilterApplied = true;
			setShowFilterApplied(showFilterApplied);
		});
		if (dimensionError !== -1) {
			setDimensionValidation(dimensionError);
			return;
		}

		// const typeCheck = props?.customParameterList;
		// if(typeCheck.isArray && typeCheck.length > 0)

		// check for ad account dropdown - if empty in pmt report
		const checkIfAccountId = selectedFilters.hasOwnProperty('ad_account');

		if (checkIfAccountId && selectedFilters?.ad_account?.length === 0) {
			toaster.addToast({
				message: 'Please select at least one Ad Account ID',
				timeout: 3000,
				type: 'error',
			});
			return;
		}

		const isValidationFalse = filters.find((index: any) => {
			if (index.required && !selectedFilters[index.id]) {
				return true;
			}
		});

		if (isValidationFalse) {
			return;
		}
		if (props.showRadioFilter) {
			selectedFilters.sales_touches = salesTouchRadio;
		}
		props.onSubmit(selectedFilters);
		if (props.updateResetState) {
			props.updateResetState(showFilterApplied);
		}
		handleCloseDialog();
	};
	const onResetFilters = () => {
		selectedFilters = { ...props.onReset() };
		dimensionValidation = -1;
		validationETM = false;
		if (props?.selectedFilters?.excludes) {
			chipsList.length = 0;
		}
		submitFilters();
		setSelectedFilters({ ...selectedFilters });
		setDimensionValidation(-1);
		setValidationETM(false);
		setShowFilterApplied(false);
	};
	const salesTouch = (event: any) => {
		setSalesTouchRadio(event.target.value);
		selectedFilters.sales_touches = salesTouchRadio;
		setSelectedFilters({ ...selectedFilters });
	};
	const andOrOperator = (event: any) => {
		relationalOperator = Number(event.target.value);
		setRelationalOperator(relationalOperator);
		selectedFilters.relational_operator = relationalOperator;
		setSelectedFilters({ ...selectedFilters });
	};
	const onFilterUpdated = (evt: any, valueOF: any) => {
		selectedFilters[valueOF] = evt?.target?.value || evt;
		setSelectedFilters({ ...selectedFilters });
	};
	const assignKeyname = (val: string) => {
		switch (val) {
			case 'Attribution Model':
				return 'attribution_model';
			case 'Segment Type':
				return 'segment_id';
			case 'Date Type':
				return 'date_type';
			case 'Connectors':
				return 'connector';
			default:
				return '';
		}
	};
	const checkDuplicateChips = (evt: any) => {
		if (evt?.trim()) {
			return chipsList.find((val: any) => val.name === evt);
		}
		return false;
	};
	const isFilterDefault = (id: any): boolean => {
		const defaultFilter = props.filters.find((item: any) => item.id === id);
		if (defaultFilter?.required || defaultFilter?.isDisabled) {
			return false;
		}
		return true;
	};
	const handleChipDelete = (index: any, evt: any) => {
		chipsList.splice(index, 1);
		if (textAreaValCopy === evt) {
			setChipValues('');
			setTextAreaValCopy('');
		}
		const findDelete = deletedAttributes.current?.findIndex(
			(deleteVal: any, deleteI: any) => deleteVal === evt
		);
		if (findDelete === -1) {
			deletedAttributes.current.push(evt);
		}
		setChipsList([...chipsList]);
		setCurrentChipIndex(-1);
	};
	//------------------------------------------------------------

	//Dropdown map ---------------------------
	function DropdownsMap(filter: any) {
		return (
			<Grid item xs={4} className='filterModal__modalContainer__dropdown'>
				<Dropdown
					itemList={filter.data}
					label='name'
					labelName={filter.name || 'name'}
					disabled={filter.isDisabled}
					valueSelector='value'
					name={filter.name}
					className={filter.className}
					defaultValue={selectedFilters[filter.id]}
					onChange={(evt: any) => onFilterUpdated(evt, filter.id)}
					error={
						!selectedFilters[filter.id] && validationETM && filter?.required
					}
				/>
				{selectedFilters[filter.id] && isFilterDefault(filter.id) ? (
					<div
						className='clear_btn_icon'
						onClick={() => {
							const keyName = assignKeyname(filter.name);
							if (filter.name === 'Segment Type') {
								setSelectedFilters({
									...selectedFilters,
									[`${keyName}`]: null,
								});
								return;
							}
							setSelectedFilters({
								...selectedFilters,
								[`${keyName}`]: '',
							});
						}}
						role='button'
						tabIndex={0}
						onKeyDown={() => {}}
					>
						<ToolTipOnText title={`Clear ${filter.name || ''}`}>
							<ClearIcon style={{ color: 'grey' }} />
						</ToolTipOnText>
					</div>
				) : (
					<></>
				)}
			</Grid>
		);
	}
	//----------------------------------------

	//Dimensions Block ---------------------------
	function DimensionsBlock(filter: any) {
		return (
			<Grid
				item
				xs={12}
				className='filterModal__modalContainer__dimension__dropdown'
			>
				<Grid item xs={1.8}>
					{' '}
					<Typography variant='h6' component='span' align='left'>
						{filter.name}
					</Typography>
				</Grid>
				<Grid item xs={10}>
					<Dimensions
						selector={filter.id}
						customParameterList={props?.customParameterList ? true : false}
						parameterList={
							props?.customParameterList
								? props?.customParameterList
								: filter?.parameters
						}
						operatorList={filter.operators}
						selectedConditions={selectedFilters[filter?.id]}
						onConditionUpdated={(conditions: any) =>
							onConditionUpdated(conditions, filter?.id)
						}
						dimensionError={dimensionValidation}
						campaignTypeArr={props.campaignTypeArr}
						realtionOperator={relationalOperator}
						deleteIconColor={'red'}
						hideOrTab={true}
						hideAndTab={true}
						addButton={true}
					/>
				</Grid>
			</Grid>
		);
	}

	return (
		<>
			<div className='filterModal' style={{ position: 'relative' }}>
				{/* <Badge color='primary' variant='dot' > */}
				{showFilterApplied ? (
					<div
						className='circlePosition'
						onClick={() => {
							props?.customParameterList ||
							props?.showCustomType ||
							props?.campaignTypeArr
								? resetFilterRef.current.handleClickOpen()
								: onResetFilters();
						}}
					>
						<ToolTipOnText title='Reset' placement='bottom'>
							<CancelIcon
								// onClick={onResetFilters}
								fontSize='small'
								onFocus={() => {
									setDisablePointerEvents(true);
								}}
								onBlur={() => {
									setDisablePointerEvents(false);
								}}
								className='circlePosition__svg'
								color='warning'
								style={{ fill: '#cc3333' }}
							/>
						</ToolTipOnText>
					</div>
				) : (
					''
				)}
				<Grid
					onClick={() => {
						filterButtonHandler();
					}}
					className={'filterIcon'}
					style={{
						cursor: 'pointer',
						position: 'relative',
						// marginRight: theme.palette.mode === 'dark' ? '12px' : '',
					}}
					mr={3.5}
					ml={-1.0}
					aria-disabled={
						props.isDisabled ? props.isDisabled : disablePointerEvents
					}
				>
					<ToolTipOnText
						title='Filters'
						style={{
							display: 'flex',
							padding: '4px',
						}}
					>
						<FilterIcon {...data} />{' '}
						{/* FilterIcon Displayed on header data paasinf is color,height, width */}
					</ToolTipOnText>
				</Grid>
				<Modal open={handleOpenDialog} onClose={handleCloseDialog}>
					<Box
						className={
							props.type
								? 'socialMedia__modalContainer'
								: 'filterModal__modalContainer'
						}
						bgcolor={theme.palette.background.light}
					>
						<IconButton
							className='filterModal__closeBtn'
							onClick={handleCloseDialog}
						>
							<CancelIcon />
						</IconButton>

						<Grid
							container
							style={{
								minHeight: props.type ? '30vh' : '50vh',
								display: 'block',
							}}
						>
							<Typography variant='h4' component='div' align='left'>
								Filters
							</Typography>
							{props.showRadioFilter ? (
								<FormControl
									style={{
										display: 'flex',
										justifyContent: 'flex-start',
										alignItems: 'center',
										marginTop: '1.5rem',
										flexDirection: 'row',
									}}
								>
									<Typography variant='h5' component='div' align='left'>
										Include Sales Touch
									</Typography>
									<RadioGroup
										row
										style={{ marginLeft: '3rem' }}
										defaultValue='no'
										onChange={salesTouch}
										value={salesTouchRadio}
									>
										<FormControlLabel
											value='yes'
											control={<Radio />}
											label='Yes'
										/>
										<FormControlLabel
											value='no'
											control={<Radio />}
											label='No'
										/>
									</RadioGroup>
								</FormControl>
							) : (
								''
							)}

							<Grid container xs={12} mt={3}>
								{filters?.map(
									(filter: any, index: any) =>
										filter.type == 'dropdown' && <DropdownsMap {...filter} />
								)}
							</Grid>
							{/* Dimensions Block --------------------------------------------------- */}
							<Grid
								container
								xs={12}
								mt={3}
								className='filterModal__dimensionBlock'
							>
								<div className='filterModal__dimensionBlock__conditions'>
									<Typography variant='h6' component='span' align='left'>
										Select Condition
									</Typography>
									<FormControl
										style={{
											display: 'flex',
											justifyContent: 'flex-start',
											alignItems: 'center',
											// marginTop: '1.5rem',
											flexDirection: 'row',
										}}
									>
										<RadioGroup
											row
											style={{ marginLeft: '3rem' }}
											defaultValue={1}
											onChange={andOrOperator}
											value={relationalOperator}
										>
											<FormControlLabel
												value={1}
												control={<Radio />}
												label='AND'
											/>
											<FormControlLabel
												value={2}
												control={<Radio />}
												label='OR'
											/>
										</RadioGroup>
									</FormControl>
								</div>
								{filters?.map(
									(filter: any, index: any) =>
										filter.type == 'conditional' && (
											<DimensionsBlock {...filter} />
										)
								)}
							</Grid>
							{/* Exclude block -------------------------------------- */}
							{props.showExcludeItem ? (
								<Grid
									container
									xs={4}
									mt={3}
									key={chipsList.length}
									className='filterModal__excludeBlock'
								>
									<Input
										autoFocus={true}
										name='filterField'
										label='Always exclude Item'
										placeholder='Always exclude Item'
										onChange={(event: any) => {
											if (
												event.target.value.trim() !== '' &&
												event.nativeEvent.inputType !== 'insertFromPaste'
											) {
												setTextAreaValCopy(event.target.value);
											}
											if (event.nativeEvent.inputType !== 'insertFromPaste') {
												if (!event.target.value.includes('\n')) {
													setChipValues(event.target.value);
												}
											}
											if (event.target.value.includes('\n')) {
												setChipValues('');
												setTextAreaValCopy('');
											}
										}}
										onKeyPress={(event: any) => {
											if (event.target.value.trim() !== '') {
												setTextAreaValCopy(event.target.value);
											}
											let eventVal = event.target.value;
											const x = event.keyCode;
											const y = event.code;

											if (event.code == 'Enter') {
												setTextAreaValCopy('');
												if (eventVal.trim() == '') {
													return;
												}
												if (currentChipIndex > -1) {
													deletedAttributes.current.push(
														chipsList[currentChipIndex].name
													);

													if (
														!chipsList
															.map((i: any) => i.name.trim().toLowerCase())
															.includes(eventVal.trim().toLowerCase())
													) {
														chipsList[currentChipIndex].name = eventVal;
														setChipsList(chipsList);
														setCurrentChipIndex(-1);
													}
												} else {
													if (checkDuplicateChips(eventVal)) {
														setChipValues('');
														setTextAreaValCopy('');
														event.preventDefault();
														return;
													}
													let findAddI = addedAttributes.current?.findIndex(
														(addVal: any, addI: any) => addVal === eventVal
													);
													const findDelete =
														deletedAttributes.current?.findIndex(
															(deleteVal: any, deleteI: any) =>
																deleteVal === eventVal
														);
													if (findDelete !== -1) {
														if (findAddI !== -1) {
															addedAttributes.current.splice(findAddI, 1);
														}
														const deleteList = deletedAttributes.current;
														deleteList.splice(findDelete, 1);
														deletedAttributes.current = deleteList;
														findAddI = 0;
													}
													if (findAddI === -1) {
														addedAttributes.current.push(eventVal);
													}
													if (
														!chipsList
															.map((i: any) => i.name.trim().toLowerCase())
															.includes(eventVal.trim().toLowerCase())
													) {
														chipsList.push({
															name: eventVal,
															isDefault: 0,
															isEnabled: 1,
														});
														setChipsList([...chipsList]);
													}
												}
												setChipValues('');
												setTextAreaValCopy('');
												event.preventDefault();
											}
										}}
										defaultValue=''
										InputLabelProps={{
											classes: {
												root: classes.inputLabel,
												focused: classes.focused,
											},
										}}
									/>
									{chipsList.length ? (
										<div className='filterModal__excludeBlock__chips'>
											{chipsList.map((i: any, index: number) => (
												<Chip
													color='default'
													style={{
														display:
															index > 1 && showChips == false ? 'none' : '',
													}}
													label={i.name}
													onClick={
														i.isDefault
															? undefined
															: (ev: any) => {
																	setCurrentChipIndex(index);
																	setChipValues(ev.target.textContent);
																	setTextAreaValCopy(ev.target.textContent);
															  }
													}
													variant={
														index === currentChipIndex ? 'outlined' : 'filled'
													}
													className='filterModal__excludeBlock__chips__chipAlone'
													deleteIcon={<ClearIcon />}
													onDelete={(evt: any) =>
														handleChipDelete(index, i.name)
													}
												/>
											))}
										</div>
									) : (
										<Grid xs={6} position={'absolute'}>
											<Typography></Typography>
										</Grid>
									)}
								</Grid>
							) : (
								''
							)}
						</Grid>
						<Grid
							container
							justifyContent='start'
							className='filterModal__filterButtons'
						>
							<Button btnText='Apply' type='button' onClick={submitFilters} />
							<Button
								btnText='Reset'
								type='button'
								variant='outlined'
								onClick={onReset}
							/>
						</Grid>
					</Box>
				</Modal>
				<React.Suspense fallback={<h3>Loading&hellip;</h3>}>
					<AlertDialog
						ref={resetFilterRef}
						title='Reset Filters'
						description='Are you sure you want to reset filters for report?'
						confirm='Reset'
						discard='Cancel'
						color='error'
						hideCloseButton
						onConfirm={() => {
							onResetFilters();
							resetFilterRef.current.handleClose();
						}}
						onDiscard={() => {
							resetFilterRef.current.handleClose();
						}}
					/>
				</React.Suspense>
			</div>
		</>
	);
};

export default HeaderFilterModal;
