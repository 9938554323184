import { useTheme } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { MultiSelect } from 'react-multi-select-component';

import './MultiSelectDropdown.scss';

export default function MultiSelectDropdown(props: any) {
	const theme: any = useTheme();
	const [options, setOptions] = useState([]);

	useEffect(() => {
		let tempTeams: any;
		props.mapping
			? // eslint-disable-next-line
			  (tempTeams = props?.itemList?.map((item: any) => {
					return {
						label: item[props.uniqueLabel || 'label'],
						value: item[props.uniqueId || 'value'],
						disabled: item['disabled'] ? item['disabled'] : false,
						is_suggested: item['is_suggested'] || false,
					};
			  }))
			: (tempTeams = props?.itemList?.map((item: any) => {
					return {
						label: item[props.uniqueLabel || 'label'],
						value: item[props.uniqueId || 'value'],
					};
			  }));
		setOptions(tempTeams);
	}, [props.itemList]);

	return (
		<>
			<MultiSelect
				labelledBy={props?.labelName || 'multi-select-dropdownasd'}
				options={options || props?.itemList}
				disableSearch={props?.search ? props.search : false}
				disabled={props.disabled || false}
				// isOpen
				value={props.selected || []}
				onChange={(evt: any) => {
					let unCheckedItem: any = [];
					if (props?.selected?.length > evt?.length) {
						// user has un unchecked
						const difference = props.selected.filter((selectedItem: any) => {
							// Check if the selectedItem is not in evtArray
							return !evt.some(
								(evtItem: any) =>
									evtItem.label === selectedItem.label &&
									evtItem.value === selectedItem.value
							);
						});
						unCheckedItem = difference;
					}
					if (props?.onChange) {
						props.onChange(evt, unCheckedItem || []);
					}
				}}
				onMenuToggle={props?.onMenuToggle}
				isLoading={props.loading}
				ItemRenderer={props.ItemRenderer} // do not add null here as it breaks multiselect
				valueRenderer={props.valueRenderer || null}
				hasSelectAll={!props?.hasSelectAll ? false : true}
				overrideStrings={{
					clearSearch: 'Clear Fields',
					search: 'Search',
					selectAll: props.selectAllText || 'Select All Accounts',
					allItemsAreSelected:
						props.allSelectedPlaceholder || 'All Accounts are selected',
					selectSomeItems: props.placeholderText || 'Select',
				}}
				// disabled={!!props.disable}
				closeOnChangedValue={props.closeOnChangedValue || false}
				// className={props.className || 'multi-select'}
				// className={
				// 	props.className
				// 		? `${props.className} multi-select-dark`
				// 		: theme.palette.mode === 'dark'
				// 			? 'multi-select-dark'
				// 			: 'multi-select'
				// }
				className={
					theme.palette.mode === 'dark'
						? `multi-select-dark ${props.className || ''}`
						: `multi-select ${props.className || ''}`
				}
			/>
		</>
	);
}
