import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import useToast from '../components/Toast/hooks/useToast';
import { LogoutSession } from './logutSessionFromKeyCloak';
import { useAppContext } from '../AppContext/App.context';

const useAxiosInterceptor = () => {
	const toaster = useToast();
	const [appData, dispatch]: any = useAppContext();
	const { userData, theme: appThemeGlobalContext } = appData;

	const instance = axios.create({
		validateStatus: (status) => status >= 200 && status < 300,
	});

	// Function to refresh the access token
	const generateAccessTokenWithRefreshToken = async (originalRequest: any) => {
		if (userData) {
			const refreshToken = userData['refresh-token'];
			if (refreshToken) {
				const apiUrl = `${process.env.REACT_APP_API_URL}kcauth/refreshToken?refresh_token=${refreshToken}`;
				try {
					const response = await fetch(apiUrl, { method: 'GET' });
					if (response.status === 440) {
						toaster.addToast({
							message: 'Session Expired',
							timeout: 2000,
							type: 'error',
						});
						localStorage.clear();
						const Client_Data = localStorage.getItem('Client_data') || '';
						localStorage.setItem('Client_data', Client_Data);
						LogoutSession.logoutSessionFromKeyCloak(); // Call the logout function
						window.location.href = '/login';
					}
					const responseData = await response.json();
					if (responseData.data) {
						userData['refresh-token'] = responseData.data.refresh_token;
						userData['access-token'] = responseData.data.access_token;
						localStorage.setItem('userData', JSON.stringify(userData));

						// Retry the original request with new tokens
						originalRequest.headers['access-token'] =
							responseData.data.access_token;
						return instance(originalRequest);
					}
				} catch (error) {
					toaster.addToast({
						message: 'Session Expired',
						timeout: 2000,
						type: 'error',
					});
					localStorage.clear();
					const Client_Data = localStorage.getItem('Client_data') || '';
					localStorage.setItem('Client_data', Client_Data);
					LogoutSession.logoutSessionFromKeyCloak(); // Call the logout function
					window.location.href = '/login';
				}
			}
		}
		return Promise.reject('Session Expired.');
	};

	instance.interceptors.response.use(
		(response) => response.data,
		async (error) => {
			const originalRequest = error.config;

			if (
				error.response &&
				error.response.status === 440 &&
				!originalRequest._retry
			) {
				originalRequest._retry = true;
				try {
					return await generateAccessTokenWithRefreshToken(originalRequest);
				} catch (retryError) {
					return Promise.reject(retryError);
				}
			}

			if (
				error.response &&
				(error.response.status === 500 || error.response.status === 404)
			) {
				return Promise.reject(error.response.data);
			}

			return Promise.reject(error.response.data);
		}
	);

	return instance;
};

export default useAxiosInterceptor;
