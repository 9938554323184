import { useCallback, useState } from 'react';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import useLocalStorage from '../../utils/localStorage';
import { useSelector } from 'react-redux';
import { useSidebar } from '../../utils/SideBarContext';
const usePdfModifier = (modifications) => {
	const dpi = 96; // Standard DPI for most displays
	const { setShowSidebar } = useSidebar();
	const csvLink = useLocalStorage?.getItem('csvLink');
	const rowsCountForPdf = useSelector((state) => state?.rowsCountForPdf);
	const campaignPerformancePageLimit = useLocalStorage.getItem(
		'campaignPerformancePageLimit'
	);
	const count = useLocalStorage.getItem('count');
	const cloneAndModifyElements = (rootId, modifications) => {
		const originalElement = document.getElementById(rootId);
		if (!originalElement) {
			console.error(`Element with id "${rootId}" not found.`);
			return null;
		}

		// Deep clone the original element
		const clonedElement = originalElement.cloneNode(true);

		// Apply modifications to the cloned element
		// modifications(clonedElement);
		return clonedElement;
	};

	const pxToMm = useCallback((px) => {
		// Convert pixels to millimeters using the formula: mm = px * 25.4 / dpi
		const mm = (px * 25.4) / dpi;
		return mm;
	}, []);

	const mmToPx = useCallback((mm) => {
		// Convert millimeters to pixels using the formula: px = mm * dpi / 25.4
		const px = (mm * dpi) / 25.4;
		return px;
	}, []);

	const range = useCallback((start, end) => {
		return Array(end - start)
			.fill()
			.map((_, idx) => start + idx);
	}, []);

	const applyModifications = useCallback(
		async (element) => {
			if (modifications) {
				const {
					hideSidebar,
					backgroundColor,
					topHeader,
					tableHeightClass,
					topNav,
				} = modifications;
				const noDataFound = document.getElementsByClassName(
					'no_records_to_display'
				);
				if (hideSidebar) {
					const sidebar = document.getElementById(hideSidebar);
					const header = document.getElementsByClassName(topHeader);
					const topNavheader = document.getElementsByClassName(topNav);
					const heightRemove = document.getElementsByClassName('app__outer');
					if (sidebar) {
						sidebar.style.display = 'none';
						header[0].style.display = 'none';
						topNavheader?.length > 0 &&
							(topNavheader[0].style.display = 'none');
						heightRemove?.length > 0 &&
							(heightRemove[0].style.height = 'unset');
					}
				}
				if (noDataFound?.length > 0) {
					noDataFound[0].style.height = '180px';
				}
			}
		},
		[modifications]
	);
	const capturePage = async (pathName, pdfName) => {
		try {
			// await applyModifications();
			document.body.style.margin = '0 auto';

			// Get the element by pathName
			const input = document.getElementById(pathName);

			if (!input) {
				console.error(`Element with id "${pathName}" not found.`);
				throw new Error('Element not found');
			}

			const inputHeightMm = pxToMm(
				input?.offsetHeight < 1293 ? 1293 : input?.offsetHeight
			);
			const a4WidthMm = window.devicePixelRatio >= 1 ? 350 : 450;
			const a4HeightMm = 297;
			const a4HeightPx = mmToPx(a4HeightMm);
			const numPages =
				inputHeightMm <= a4HeightMm
					? 1
					: Math.floor(inputHeightMm / a4HeightMm) + 1;

			const canvas = await html2canvas(input, {
				allowTaint: true,
				useCORS: true,
				dpi: 300,
				letterRendering: true,
				logging: false,
				scale: 1.5,
			});

			const imgData = canvas.toDataURL('image/png');
			// Document of a4WidthMm wide and inputHeightMm high;
			let pdf;
			let adjustedHeightMm =
				// campaignPerformancePageLimit  === 5 ? 250 : inputHeightMm;
				rowsCountForPdf === 5 ? 250 : inputHeightMm;
			if (count < 5) {
				adjustedHeightMm = 180;
			}
			if (inputHeightMm > a4HeightMm) {
				// elongated a4 (system print dialog will handle page breaks)
				pdf = new jsPDF('p', 'mm', [inputHeightMm - 16, a4WidthMm]);
			} else {
				// standard a4
				pdf = new jsPDF();
			}
			if (inputHeightMm < 450) {
				pdf.addImage(imgData, 'PNG', 0, 0, a4WidthMm, 0);
			} else {
				pdf.addImage(imgData, 'PNG', 0, 0, a4WidthMm, inputHeightMm - 16); // ----> adjust this height dynamically.
			}

			// pdf.addImage(imgData, 'PNG', 0, 0, a4WidthMm, adjustedHeightMm);
			pdf.save(`${pdfName || pathName}.pdf`);

			// Optionally hide loader and close window
			setTimeout(() => {
				window.location.reload();
			}, 500);
		} catch (error) {
			console.error('Error generating PDF', error);
			window.location.reload();
			throw error;
		}
	};

	const paidCampanpaignCapture = async (pathName, getCsvLink) => {
		document.body.style.margin = '0 auto';
		applyModifications();

		const input = document.getElementById(pathName);
		const inputHeightMm = pxToMm(
			input?.offsetHeight < 1293 ? 1293 : input?.offsetHeight
		);

		const a4WidthMm = window.devicePixelRatio >= 1 ? 350 : 450;
		const a4HeightMm = 297;
		const a4HeightPx = mmToPx(a4HeightMm);
		const numPages =
			inputHeightMm <= a4HeightMm
				? 1
				: Math.floor(inputHeightMm / a4HeightMm) + 1;
		// console.log({
		// 	input,
		// 	inputHeightMm,
		// 	a4HeightMm,
		// 	a4HeightPx,
		// 	numPages,
		// 	range: range(0, numPages),
		// 	comp: inputHeightMm <= a4HeightMm,
		// 	inputHeightPx: input?.offsetHeight,
		// });
		await html2canvas(input, {
			allowTaint: true,
			// @ts-ignore
			dpi: 300,
			letterRendering: true,
			logging: false,
			scale: 1.5,
		})
			.then((canvas) => {
				const imgData = canvas.toDataURL('image/png');

				// Document of a4WidthMm wide and inputHeightMm high;
				let pdf;
				let adjustedHeightMm =
					campaignPerformancePageLimit === 5 ? 250 : inputHeightMm;
				if (count < 5) {
					adjustedHeightMm = 180;
				}
				if (inputHeightMm > a4HeightMm) {
					// elongated a4 (system print dialog will handle page breaks)
					pdf = new jsPDF('p', 'mm', [inputHeightMm + 16, a4WidthMm]);
				} else {
					// standard a4
					pdf = new jsPDF();
				}

				const clickableText = 'Click here to download the CSV file*';
				const clickableFontSize = 16;

				// Define the non-clickable text
				const additionalText = 'CSV available till 6 months';
				const additionalFontSize = 10;

				// Set font size for the clickable text and calculate its width
				pdf.setFontSize(clickableFontSize);
				// pdf.setTextColor('blue');
				pdf.setFont(clickableText, 'italic');
				const clickableTextWidth =
					(pdf.getStringUnitWidth(clickableText) * clickableFontSize) /
					pdf.internal.scaleFactor;
				// Calculate positions
				const x = 10;
				const y = adjustedHeightMm + 10;
				pdf.addImage(imgData, 'PNG', 0, 0, a4WidthMm, adjustedHeightMm);
				//color for text
				pdf.setTextColor('blue');
				pdf.text(clickableText, x, y);
				pdf.link(
					x,
					y - 4,
					clickableTextWidth,
					clickableFontSize / pdf.internal.scaleFactor,
					{ url: getCsvLink }
				);
				pdf.setFontSize(additionalFontSize);
				// Add additional non-clickable text next to the clickable text
				pdf.setTextColor('black');
				pdf.text(additionalText, x + clickableTextWidth, y);
				pdf.save(`Paid-Campaign-Tracker.pdf`);
				//    loader.hideLoader();
				setTimeout(() => {
					//    window.close();
					window.location.reload();
				}, 500);
			})
			.catch((error) => {
				console.log('paid campaign pdff errrror-->', error);
				window.location.reload();
			});
	};

	const capturePageCmoDrill = async (pathName, pdfName, getCsvLink) => {
		try {
			// await applyModifications();
			document.body.style.margin = '0 auto';

			// Get the element by pathName
			const input = document.getElementById(pathName);

			if (!input) {
				console.error('Element with id "pdfElementId-paid" not found.');
				throw new Error('Element not found');
			}

			const inputHeightMm = pxToMm(
				input?.offsetHeight < 1293 ? 1293 : input?.offsetHeight
			);
			const a4WidthMm = window.devicePixelRatio >= 1 ? 350 : 450;
			const a4HeightMm = 297;
			const a4HeightPx = mmToPx(a4HeightMm);
			const numPages =
				inputHeightMm <= a4HeightMm
					? 1
					: Math.floor(inputHeightMm / a4HeightMm) + 1;

			const canvas = await html2canvas(input, {
				allowTaint: true,
				useCORS: true,
				dpi: 300,
				letterRendering: true,
				logging: false,
				scale: 1.5,
			});

			const imgData = canvas.toDataURL('image/png');
			// Document of a4WidthMm wide and inputHeightMm high;
			let pdf;
			let adjustedHeightMm =
				// campaignPerformancePageLimit  === 5 ? 250 : inputHeightMm;
				rowsCountForPdf === 5 ? 250 : inputHeightMm;
			if (count < 5) {
				adjustedHeightMm = 180;
			}
			if (inputHeightMm > a4HeightMm) {
				// elongated a4 (system print dialog will handle page breaks)
				pdf = new jsPDF('p', 'mm', [inputHeightMm + 16, a4WidthMm]);
			} else {
				// standard a4
				pdf = new jsPDF();
			}

			///  -----------------cmo drill start --------------------------//

			const clickableText = 'Click here to download the CSV file*';
			const clickableFontSize = 16;

			// Define the non-clickable text
			const additionalText = 'CSV available till 6 months';
			const additionalFontSize = 10;

			// Set font size for the clickable text and calculate its width
			pdf.setFontSize(clickableFontSize);
			// pdf.setTextColor('blue');
			pdf.setFont(clickableText, 'italic');
			const clickableTextWidth =
				(pdf.getStringUnitWidth(clickableText) * clickableFontSize) /
				pdf.internal.scaleFactor;
			// Calculate positions
			const x = 10;
			const y = inputHeightMm < 350 ? inputHeightMm - 70 : inputHeightMm + 10;
			// const y = inputHeightMm + 10;
			if (inputHeightMm < 450) {
				pdf.addImage(imgData, 'PNG', 0, 0, a4WidthMm, 0);
			} else {
				pdf.addImage(imgData, 'PNG', 0, 0, a4WidthMm, inputHeightMm - 16); // ----> adjust this height dynamically.
			}
			//color for text
			pdf.setTextColor('blue');
			pdf.text(clickableText, x, y);
			pdf.link(
				x,
				y - 4,
				clickableTextWidth,
				clickableFontSize / pdf.internal.scaleFactor,
				{ url: getCsvLink }
			);
			pdf.setFontSize(additionalFontSize);
			// Add additional non-clickable text next to the clickable text
			pdf.setTextColor('black');
			pdf.text(additionalText, x + clickableTextWidth, y);

			/// ----------------------------cmo drill end ----------------------//

			// pdf.addImage(imgData, 'PNG', 0, 0, a4WidthMm, adjustedHeightMm);
			pdf.save(`${pdfName || pathName}.pdf`);

			// Optionally hide loader and close window
			setTimeout(() => {
				window.location.reload();
			}, 500);
		} catch (error) {
			console.error('Error generating PDF', error);
			window.location.reload();
			throw error;
		}
	};

	return {
		pxToMm,
		mmToPx,
		range,
		capturePage,
		paidCampanpaignCapture,
		capturePageCmoDrill,
		applyModifications,
	};
};

export default usePdfModifier;
