import {
	SET_CMO_CLOSED_WON_DATA,
	SET_CMO_INSIGHTS_TABLE_DATA,
	SET_CMO_PIPELINE_API_DATA,
	SET_CMO_REQUEST_DATA,
	SET_CMO_GRAPH_DATA,
	SET_CMO_REQUEST_BODY,
	SET_CMO_BOOKMARK_REQUEST_BODY_DATA,
	SET_CMO_METRIC_VALUE,
	SET_CMO_DIMENSION_VALUE,
	RESET_TO_INTIAL_STATE,
	PDF_CLICKED,
	PDF_COLUMNS,
	TABLEDATA_FOR_PDF,
	ROWS_COUNT_PDF,
	PATH_SAME_DRILL_DOWN,
	DRILL_DOWN_CSV_HIDE_OPTION,
	GLOBAL_FILTER_DATA,
} from '../actionTypes/actionType';
import formatNumber from '../utils/metricSuffix';
import { colors } from '../containers/CMODashboard/CmoDashboardConstants';
import twoDecimal from '../utils/decimalPercentage';
import { monthsArray } from '../containers/Reports/Dashboard/InsightsData';
import { monthDiff } from '../containers/Reports/analytic-report-trend/analytic-report-data';
import { InsightsData } from '../containers/Reports/Dashboard/InsightsData';
const intinalState = {
	cmo_closed_won: {
		roi_cards_data: null,
		cards_data: null,
		closed_won_card_data: null,
	},
	average_sale_cycle_data: null,
	average_deal_cycle_data: null,
	cmo_pipeline_data: {
		roi_cards_data: null,
		donut_data: null,
		box_data: null,
	},
	cmo_insights_table_data: null,
	cmo_request_body: {
		date_range: InsightsData.getDefaultRange(),
		filter_data: InsightsData.getDefaultCMOFilters(),
	},
	cmo_bookmark_request_body: null,
	cmo_metric_value: 'spend',
	cmo_dimension_value: 'channel',
	isPdfClicked: false,
	// selectedColumnForPdf: [], // this is previous handling
	selectedColumnForPdf: {
		key: '',
		selectedArray: [],
	},
	pathSameDrillDown: false, // pathSameDrillDownValue , is use beacause when have same path but drill down , so to show only multiselect in drill down  we are using pathSameDrillDownValue key
	isTableDataForPdf: {},
	rowsCountForPdf: 5,
	drillDownCsvHideValue: false,
	global_filter_data: {},
};
let requestData = {};

export const cmoDashboardReducer = (state = intinalState, action) => {
	// Two graphs data Marketing ROI and CPO ------------------------------------------------------
	function getClosedWonData(data) {
		return {
			closed_won: {
				ROI: [...data?.closed_won?.Marketing_ROI?.graph_data],
				CPO: [...data?.closed_won?.CPO?.graph_data],
				// ROI_change: data?.mi_roi_comp + data?.ms_roi_comp,
				// CPO_change: data?.mi_cpo_comp + data?.ms_cpo_comp,
				value: [
					`${(data?.closed_won?.Marketing_ROI?.block_data[0]?.value).toFixed(
						2
					)}x`,
					`$${formatNumber(data?.closed_won?.CPO?.block_data[0]?.value)}`,
				],
				change: [
					data?.closed_won?.Marketing_ROI?.block_data[0]?.Comparison_data,
					data?.closed_won?.CPO?.block_data[0]?.Comparison_data,
				],
				change2: [
					data?.closed_won?.Marketing_ROAS?.block_data[0]?.Comparison_data,
					data?.closed_won?.CPO?.block_data[0]?.Comparison_data,
				],
				ROAS_change:
					data?.closed_won?.Marketing_ROAS?.block_data[0]?.Comparison_data,
				ROAS: [...data?.closed_won?.Marketing_ROAS?.graph_data],
				ROAS_value: [
					`${(data?.closed_won?.Marketing_ROAS?.block_data[0]?.value).toFixed(
						2
					)}x`,
					`$${formatNumber(data?.closed_won?.CPO?.block_data[0]?.value)}`,
				],
			},
		};
	}

	function getCardsData(data) {
		const list = [];
		let dataToReturn = {
			donutData: null,
			cardList: [],
		};

		if (data?.closed_won) {
			//need to change response a bit manipulate so wee loop sales and others only and add data for donut-data also--------------------  compare response with beta
			// Iterate over the keys of the 'closed_won' object (e.g., Sales, Others)
			Object.keys(data.closed_won).forEach((key, index) => {
				if (key == 'Sales' || key == 'Others') {
					const section = data.closed_won[key];
					// Only proceed if the section has block_data
					if (section?.block_data) {
						// section.block_data.forEach((item, blockIndex) => {
						// if (item.key) {
						dataToReturn.cardList.push({
							name: key == 'Sales' ? 'Sales' : 'Others',
							total:
								key == 'Sales'
									? data.closed_won?.Sales?.block_data[2]?.value
									: data.closed_won?.Others?.block_data[2]?.value, // Use `value` from block_data as total
							total_change:
								key == 'Sales'
									? data.closed_won?.Sales?.block_data[2]?.Comparison_data
									: data.closed_won?.Others?.block_data[2]?.Comparison_data, // Use `Comparison_data` for change
							oppts:
								key == 'Sales'
									? data.closed_won?.Sales?.block_data[0]?.value
									: data.closed_won?.Others?.block_data[0]?.value,
							oppts_change:
								key == 'Sales'
									? data.closed_won?.Sales?.block_data[0]?.Comparison_data
									: data.closed_won?.Others?.block_data[0]?.Comparison_data,
							color: key == 'Sales' ? colors[1] : colors[2],
							revenue:
								key == 'Sales'
									? data.closed_won?.Sales?.block_data[1]?.Comparison_data
									: data.closed_won?.Others?.block_data[1]?.Comparison_data, // Revenue change data
							revenue_value:
								key == 'Sales'
									? data.closed_won?.Sales?.block_data[1]?.value
									: data.closed_won?.Others?.block_data[1]?.value, // Revenue value
						});
						// }
						// });
					}
				}
			});

			// Add donutData, including 'Marketing Influenced' and 'Marketing Sourced'
			dataToReturn.donutData = [
				{
					name: 'Marketing Influenced',
					y: data?.closed_won?.donut_graph_data.graph_data[0]?.values[2],
					color: '#9149FF',
				},
				{
					name: 'Marketing Sourced',
					y: data?.closed_won?.donut_graph_data.graph_data[0]?.values[1],
					color: '#133463',
				},
				{
					name: 'Sales',
					y: data.closed_won?.Sales?.block_data[1]?.value,
					color: colors[1],
				},
				{
					name: 'Others',
					y: data.closed_won?.Others?.block_data[1]?.value,
					color: colors[2],
				},

				...list,
			];
		}
		return dataToReturn;
	}

	function getAverageSaleCycleData(data) {
		return {
			overall: {
				value: data?.Avg_Sales_Cycle?.block_data[0]?.value
					? data?.Avg_Sales_Cycle?.block_data[0]?.value
					: 0,
				change:
					twoDecimal(data?.Avg_Sales_Cycle?.block_data[0]?.Comparison_data) ||
					0,
			},
			influenced: {
				value: data?.Avg_Sales_Cycle?.block_data[1]?.value
					? data?.Avg_Sales_Cycle?.block_data[1]?.value
					: 0,
				change:
					twoDecimal(data?.Avg_Sales_Cycle?.block_data[1]?.Comparison_data) ||
					0,
			},
			sourced: {
				value: data?.Avg_Sales_Cycle?.block_data[2]?.value
					? data?.Avg_Sales_Cycle?.block_data[2]?.value
					: 0,
				change:
					twoDecimal(data?.Avg_Sales_Cycle?.block_data[2]?.Comparison_data) ||
					0,
			},
		};
	}

	function getAverageDealCycleData(data) {
		return {
			overall: {
				value: data?.Avg_Deal_Size?.block_data[0]?.value
					? formatNumber(data?.Avg_Deal_Size?.block_data[0]?.value)
					: 0,
				change:
					twoDecimal(data?.Avg_Deal_Size?.block_data[0]?.Comparison_data) || 0,
			},
			influenced: {
				value: data?.Avg_Deal_Size?.block_data[1]?.value
					? formatNumber(data?.Avg_Deal_Size?.block_data[1]?.value)
					: 0,
				change:
					twoDecimal(data?.Avg_Deal_Size?.block_data[1]?.Comparison_data) || 0,
			},
			sourced: {
				value: data?.Avg_Deal_Size?.block_data[2]?.value
					? formatNumber(data?.Avg_Deal_Size?.block_data[2]?.value)
					: 0,
				change:
					twoDecimal(data?.Avg_Deal_Size?.block_data[2]?.Comparison_data) || 0,
			},
		};
	}

	function getClosedWonCardData(data) {
		return {
			// 3 boxes of closed won --------------------------
			box_values: [
				{
					name: 'Total Closed Won',
					value:
						data?.closed_won?.total_closed_won_block_data?.block_data[0]
							?.value || 0,
					change:
						twoDecimal(
							data?.closed_won?.total_closed_won_block_data?.block_data[0]
								?.Comparison_data
						) || 0,
				},
				{
					name: 'Avg. Size (Overall)',
					value:
						data?.closed_won?.total_closed_won_block_data?.block_data[1]
							?.value || 0,
					change:
						twoDecimal(
							data?.closed_won?.total_closed_won_block_data?.block_data[1]
								?.Comparison_data
						) || 0,
				},
				{
					name: 'Marketing Spent',
					value:
						data?.closed_won?.total_closed_won_block_data?.block_data[2]
							?.value || 0,
					change:
						twoDecimal(
							data?.closed_won?.total_closed_won_block_data?.block_data[2]
								?.Comparison_data
						) || 0,
				},
			],
			// Donut graph data --------------------------
			venn_data: [
				{
					name: 'Total Revenue',
					value:
						data?.closed_won?.donut_graph_data?.graph_data[0]?.values[0] || 0,
					change: twoDecimal(data?.closed_won?.total_revenue_comp) || 0,
				},
				{
					name: 'Marketing Influenced',
					value:
						data?.closed_won?.donut_graph_data?.graph_data[0]?.values[2] || 0,
					change: twoDecimal(data?.closed_won?.mrkt_influenced_comp) || 0,
				},
				{
					name: 'Marketing Sourced',
					value:
						data.closed_won &&
						data?.closed_won?.donut_graph_data?.graph_data[0]?.values[2] !== 0
							? `${data?.closed_won?.donut_graph_data?.graph_data[0]?.values[2]}`
							: 0,
					change: twoDecimal(data?.closed_won?.mrkt_sourced_comp) || 0,
				},
			],
			// data of MI and MS cards ---------------------------
			summary: [
				{
					total: {
						value: data?.closed_won?.Marketing_Influenced?.block_data[2]?.value,
						change:
							twoDecimal(
								data?.closed_won?.Marketing_Influenced?.block_data[2]
									?.Comparison_data
							) || 0,
					},
					roi: {
						value: data?.closed_won?.Marketing_Influenced?.block_data[1]?.value,
						change:
							twoDecimal(
								data?.closed_won?.Marketing_Influenced?.block_data[1]
									?.Comparison_data
							) || 0,
					},
					oppts: {
						value: data?.closed_won?.Marketing_Influenced?.block_data[0]?.value,
						change:
							twoDecimal(
								data?.closed_won?.Marketing_Influenced?.block_data[0]
									?.Comparison_data
							) || 0,
					},
					cpo: {
						value: data?.closed_won?.CPO?.block_data[0]?.value,
						change: twoDecimal(data?.closed_won?.mi_cpo_comp) || 0,
					},
					options: {
						label: 'Marketing Influenced',
						color: '#9149FF',
					},
				},
				{
					total: {
						value: data?.closed_won?.Marketing_Sourced?.block_data[2]?.value,
						change:
							twoDecimal(
								data?.closed_won?.Marketing_Sourced?.block_data[2]
									?.Comparison_data
							) || 0,
					},
					roi: {
						value: data?.closed_won?.Marketing_Sourced?.block_data[1]?.value,
						change:
							twoDecimal(
								data?.closed_won?.Marketing_Sourced?.block_data[1]
									?.Comparison_data
							) || 0,
					},
					oppts: {
						value: data?.closed_won?.Marketing_Sourced?.block_data[0]?.value,
						change:
							twoDecimal(
								data?.closed_won?.Marketing_Sourced?.block_data[0]
									?.Comparison_data
							) || 0,
					},
					cpo: {
						value: data?.closed_won?.CPO?.block_data[0]?.value,
						change: data?.closed_won?.ms_cpo_comp || 0,
					},
					options: {
						label: 'Marketing Sourced',
						color: '#133463',
					},
				},
			],
		};
	}

	function getValues(matrices, data) {
		switch (matrices) {
			case 1:
				return data?.spend;
			case 2:
				return data?.total_leads;
			case 3:
				return data?.total_pipeline;
			case 4:
				return data?.current_pipeline;
			case 5:
				return data?.total_closed_won;
			case 6:
				return data?.revenue;
			case 7:
				return data?.current_opportunity;
			case 8:
				return data?.total_opportunity;
			case 9:
				return data?.total_contacts;
			case 10:
				return data?.sessions;
			case 11:
				return data?.unique_visitors;
			case 12:
				return data?.form_filled;
			default:
				break;
		}
	}

	function getName(obj) {
		const possibleNames = [
			'channel',
			'source',
			'ad_group',
			'campaign',
			'keyword',
		];
		for (const name of possibleNames) {
			if (obj[name]) {
				return obj[name];
			}
		}
		return 'Unknown'; // or whatever default value you want
	}

	function getCmoGraphData(data, startDate, endDate, matrices) {
		if (data.length == 0) {
			return null;
		}
		let seriesLabels = [],
			seriesData = [];

		const diffrence = monthDiff(new Date(startDate), new Date(endDate)) + 1;

		data.map((item) => {
			if (!seriesLabels.includes(item.monthYear)) {
				seriesLabels.push(item.monthYear);
			}
		});
		if (seriesLabels.length == 0) {
			seriesLabels = monthsArray;
		}
		data.map((item) => {
			const yAxisValues = new Array(diffrence).fill(0);
			const foundedIndex = seriesLabels.findIndex(
				(seriesLabelItem) => item.monthYear === seriesLabelItem
			);
			yAxisValues[foundedIndex] += getValues(matrices, item);
			let isKeywordFound = false;
			seriesData.find((keywordName, index) => {
				if (keywordName?.name === getName(item)) {
					seriesData[index].data[foundedIndex] += getValues(matrices, item);
					isKeywordFound = true;
				}
			});
			if (!isKeywordFound && getName(item)) {
				seriesData.push({
					name: getName(item),
					data: [...yAxisValues],
				});
			}
		});
		return {
			seriesLabels: seriesLabels,
			seriesData: seriesData,
		};
	}

	function getCmoPipelineData(data) {
		return {
			total_pipeline: {
				ROI: [...data?.total_pipeline?.Marketing_ROI?.graph_data],
				CPO: [...data?.total_pipeline?.CPO?.graph_data],
				// ROI_change: data?.total_pipeline.mi_roi_comp + data?.total_pipeline.ms_roi_comp,
				ROAS_change:
					data?.total_pipeline?.Marketing_ROAS?.block_data[0]?.Comparison_data,
				// CPO_change:
				// 	data?.total_pipeline.mi_cpo_comp + data?.total_pipeline.ms_cpo_comp,
				value: [
					`${(data?.total_pipeline?.Marketing_ROI?.block_data[0]?.value).toFixed(
						2
					)}x`,
					`$${formatNumber(data?.total_pipeline?.CPO?.block_data[0]?.value)}`,
				],
				change: [
					data?.total_pipeline?.Marketing_ROI?.block_data[0]?.Comparison_data,
					data?.total_pipeline?.CPO?.block_data[0]?.Comparison_data,
				],
				change2: [
					data?.total_pipeline?.Marketing_ROAS?.block_data[0]?.Comparison_data,
					data?.total_pipeline?.CPO?.block_data[0]?.Comparison_data,
				],

				ROAS: [...data?.total_pipeline?.Marketing_ROAS?.graph_data],
				ROAS_value: [
					`${(data?.total_pipeline?.Marketing_ROAS?.block_data[0]?.value).toFixed(
						2
					)}x`,
					`$${formatNumber(data?.total_pipeline?.CPO?.block_data[0]?.value)}`,
				],
			},
			current_pipeline: {
				ROI: [...data?.current_pipeline?.Marketing_ROI?.graph_data],
				CPO: [...data?.current_pipeline?.CPO?.graph_data],
				// CPO_change: data?.current_pipeline.mi_cpo_comp + data?.current_pipeline.ms_cpo_comp,
				// ROI_change: data?.current_pipeline.mi_roi_comp + data?.current_pipeline.ms_roi_comp,
				ROAS_change:
					data?.current_pipeline?.Marketing_ROAS?.block_data[0]
						?.Comparison_data,
				value: [
					`${(data?.current_pipeline?.Marketing_ROI?.block_data[0]?.value).toFixed(
						2
					)}x`,
					`$${formatNumber(data?.current_pipeline?.CPO?.block_data[0]?.value)}`,
				],
				change: [
					data?.current_pipeline?.Marketing_ROI?.block_data[0]?.Comparison_data,
					data?.current_pipeline?.CPO?.block_data[0]?.Comparison_data,
				],
				change2: [
					data?.current_pipeline?.Marketing_ROAS?.block_data[0]
						?.Comparison_data,
					data?.current_pipeline?.CPO?.block_data[0]?.Comparison_data,
				],
				ROAS: [...data?.current_pipeline?.Marketing_ROAS?.graph_data],
				ROAS_value: [
					`${(data?.current_pipeline?.Marketing_ROAS?.block_data[0]?.value).toFixed(
						2
					)}x`,
					`$${formatNumber(data?.current_pipeline?.CPO?.block_data[0]?.value)}`,
				],
			},
		};
	}

	function getCmoPipleineDonutData(data) {
		const cardList_total_pipeline = [];
		const cardList_current_pipeline = [];
		const total_list = [];
		const current_list = [];
		let dataToReturn = {
			configCards: null,
			totalPipelineDonutData: null,
			currentPipelineDonutData: null,
		};

		Object.keys(data.total_pipeline).forEach((key, index) => {
			if (key == 'Sales' || key == 'Others') {
				const section = data.total_pipeline[key];
				// Only proceed if the section has block_data
				if (section?.block_data) {
					// section.block_data.forEach((item, blockIndex) => {
					// 	if (item.key) {
					cardList_total_pipeline.push({
						name: key == 'Sales' ? 'Sales' : 'Others',
						total:
							key == 'Sales'
								? data.total_pipeline?.Sales?.block_data[2]?.value
								: data.total_pipeline?.Others?.block_data[2]?.value, // Use `value` from block_data as total
						total_change:
							key == 'Sales'
								? data.total_pipeline?.Sales?.block_data[2]?.Comparison_data
								: data.total_pipeline?.Others?.block_data[2]?.Comparison_data, // Use `Comparison_data` for change
						oppts:
							key == 'Sales'
								? data.total_pipeline?.Sales?.block_data[0]?.value
								: data.total_pipeline?.Others?.block_data[0]?.value,
						oppts_change:
							key == 'Sales'
								? data.total_pipeline?.Sales?.block_data[0]?.Comparison_data
								: data.total_pipeline?.Others?.block_data[0]?.Comparison_data,
						color: key == 'Sales' ? colors[1] : colors[2],
						revenue:
							key == 'Sales'
								? data.total_pipeline?.Sales?.block_data[1]?.Comparison_data
								: data.total_pipeline?.Others?.block_data[1]?.Comparison_data, // Revenue change data
						revenue_value:
							key == 'Sales'
								? data.total_pipeline?.Sales?.block_data[1]?.value
								: data.total_pipeline?.Others?.block_data[1]?.value, // Revenue value
					});
					// 	}
					// });
				}
			}
		});
		dataToReturn.configCards = { total_pipeline: cardList_total_pipeline };
		// let total_reqArr = [];
		// total_reqArr =
		// 	data?.total_pipeline?.config?.length == 1
		// 		? data?.total_pipeline?.config
		// 		: data?.total_pipeline?.config.slice(1);
		dataToReturn.totalPipelineDonutData = [
			{
				name: 'Marketing Influenced',
				y: data.total_pipeline?.donut_graph_data?.graph_data[0]?.values[2],
				color: '#9149FF',
			},
			{
				name: 'Marketing Sourced',
				y: data.total_pipeline?.donut_graph_data.graph_data[0]?.values[1],
				color: '#133463',
			},
			{
				name: 'Sales',
				y: data.total_pipeline?.Sales?.block_data[1]?.value,
				color: colors[1],
			},
			{
				name: 'Others',
				y: data.total_pipeline?.Others?.block_data[1]?.value,
				color: colors[2],
			},

			...total_list,
		];
		Object.keys(data?.current_pipeline).forEach((key, index) => {
			if (key == 'Sales' || key == 'Others') {
				const section = data.current_pipeline[key];
				// Only proceed if the section has block_data
				if (section?.block_data) {
					// section.block_data.forEach((item, blockIndex) => {
					// 	if (item.key) {
					cardList_current_pipeline.push({
						name: key == 'Sales' ? 'Sales' : 'Others',
						total:
							key == 'Sales'
								? data.current_pipeline?.Sales?.block_data[2]?.value
								: data.current_pipeline?.Others?.block_data[2]?.value, // Use `value` from block_data as total
						total_change:
							key == 'Sales'
								? data.current_pipeline?.Sales?.block_data[2]?.Comparison_data
								: data.current_pipeline?.Others?.block_data[2]?.Comparison_data, // Use `Comparison_data` for change
						oppts:
							key == 'Sales'
								? data.current_pipeline?.Sales?.block_data[0]?.value
								: data.current_pipeline?.Others?.block_data[0]?.value,
						oppts_change:
							key == 'Sales'
								? data.current_pipeline?.Sales?.block_data[0]?.Comparison_data
								: data.current_pipeline?.Others?.block_data[0]?.Comparison_data,
						color: key == 'Sales' ? colors[1] : colors[2],
						revenue:
							key == 'Sales'
								? data.current_pipeline?.Sales?.block_data[1]?.Comparison_data
								: data.current_pipeline?.Others?.block_data[1]?.Comparison_data, // Revenue change data
						revenue_value:
							key == 'Sales'
								? data.current_pipeline?.Sales?.block_data[1]?.value
								: data.current_pipeline?.Others?.block_data[1]?.value, // Revenue value
					});
					// 	}
					// });
				}
			}
		});
		dataToReturn.configCards = {
			...dataToReturn.configCards,
			current_pipeline: cardList_current_pipeline,
		};
		// let current_reqArr = [];
		// current_reqArr =
		// 	data?.current_pipeline?.config.length == 1
		// 		? data?.current_pipeline?.config
		// 		: data?.current_pipeline?.config.slice(1);
		dataToReturn.currentPipelineDonutData = [
			{
				name: 'Marketing Influenced',
				y: data.current_pipeline?.donut_graph_data?.graph_data[0]?.values[2],
				color: '#9149FF',
			},
			{
				name: 'Marketing Sourced',
				y: data.current_pipeline?.donut_graph_data?.graph_data[0]?.values[1],
				color: '#133463',
			},
			{
				name: 'Sales',
				y: data.current_pipeline?.Sales?.block_data[1]?.value,
				color: colors[1],
			},
			{
				name: 'Others',
				y: data.current_pipeline?.Others?.block_data[1]?.value,
				color: colors[2],
			},
			...current_list,
		];
		return dataToReturn;
	}

	function getCmoPipelineBoxData(res) {
		return {
			currentPipelineData: {
				box_values: [
					{
						name: 'Total Opportunities',
						value:
							res?.current_pipeline?.current_pipeline_block_data?.block_data[0]
								?.value || 0,
						change:
							twoDecimal(
								res?.current_pipeline?.current_pipeline_block_data
									?.block_data[0]?.Comparison_data
							) || 0,
					},
					{
						name: 'Avg. Size (Overall)',
						value:
							res?.current_pipeline?.current_pipeline_block_data?.block_data[1]
								?.value || 0,
						change:
							twoDecimal(
								res?.current_pipeline?.current_pipeline_block_data
									?.block_data[1]?.Comparison_data
							) || 0,
					},
				],
				// continue from here ---------------------------------------------
				venn_data: [
					{
						name: 'Total Pipeline',
						value:
							res?.current_pipeline?.donut_graph_data?.graph_data[0]
								?.values[0] || 0,
						change: twoDecimal(res?.current_pipeline?.total_pipeline_comp) || 0,
					},
					{
						name: 'Marketing Influenced',
						value:
							res?.current_pipeline?.donut_graph_data?.graph_data[0]
								?.values[2] || 0,
						change:
							twoDecimal(res?.current_pipeline?.mrkt_influenced_comp) || 0,
					},
					{
						name: 'Marketing Sourced',
						value:
							res?.current_pipeline?.donut_graph_data?.graph_data[0]
								?.values[1] || 0,
						change: twoDecimal(res?.current_pipeline?.mrkt_sourced_comp) || 0,
					},
				],
				summary: [
					{
						total: {
							value:
								res?.current_pipeline?.Marketing_Influenced?.block_data[2]
									?.value || 0,
							change:
								twoDecimal(
									res?.current_pipeline?.Marketing_Influenced?.block_data[2]
										?.Comparison_data
								) || 0,
						},
						roi: {
							value:
								res?.current_pipeline?.Marketing_Influenced?.block_data[1]
									?.value,
							change:
								twoDecimal(
									res?.current_pipeline?.Marketing_Influenced?.block_data[1]
										?.Comparison_data
								) || 0,
						},

						oppts: {
							value:
								res?.current_pipeline?.Marketing_Influenced?.block_data[0]
									?.value || 0,
							change:
								twoDecimal(
									res?.current_pipeline?.Marketing_Influenced?.block_data[0]
										?.Comparison_data
								) || 0,
						},
						options: {
							label: 'Marketing Influenced',
							color: '#9149FF',
						},
					},
					{
						total: {
							value:
								res?.current_pipeline?.Marketing_Sourced?.block_data[2]
									?.value || 0,
							change:
								twoDecimal(
									res?.current_pipeline?.Marketing_Sourced?.block_data[2]
										?.Comparison_data
								) || 0,
						},
						roi: {
							value:
								res?.current_pipeline?.Marketing_Sourced?.block_data[1]?.value,
							change:
								twoDecimal(
									res?.current_pipeline?.Marketing_Sourced?.block_data[1]
										?.Comparison_data
								) || 0,
						},
						oppts: {
							value:
								res?.current_pipeline?.Marketing_Sourced?.block_data[0]
									?.value || 0,
							change:
								twoDecimal(
									res?.current_pipeline?.Marketing_Sourced?.block_data[0]
										?.Comparison_data
								) || 0,
						},
						options: {
							label: 'Marketing Sourced',
							color: '#133463',
						},
					},
				],
			},
			totalPipelineData: {
				box_values: [
					{
						name: 'Total Opportunities',
						value:
							res?.total_pipeline?.total_pipeline_block_data?.block_data[0]
								?.value || 0,
						change:
							twoDecimal(
								res?.total_pipeline?.total_pipeline_block_data?.block_data[0]
									?.Comparison_data
							) || 0,
					},
					{
						name: 'Avg. Size (Overall)',
						value:
							res?.total_pipeline?.total_pipeline_block_data?.block_data[1]
								?.value || 0,
						change:
							twoDecimal(
								res?.total_pipeline?.total_pipeline_block_data?.block_data[1]
									?.Comparison_data
							) || 0,
					},
				],
				venn_data: [
					{
						name: 'Total Pipeline',
						value:
							res?.total_pipeline?.donut_graph_data?.graph_data[0]?.values[0] ||
							0,
						change: twoDecimal(res?.total_pipeline?.total_pipeline_comp) || 0,
					},
					{
						name: 'Marketing Influenced',
						value:
							res?.total_pipeline?.donut_graph_data?.graph_data[0]?.values[2] ||
							0,
						change: twoDecimal(res?.total_pipeline?.mrkt_influenced_comp) || 0,
					},
					{
						name: 'Marketing Sourced',
						value:
							res?.total_pipeline?.donut_graph_data?.graph_data[0]?.values[1] ||
							0,
						change: twoDecimal(res?.total_pipeline?.mrkt_sourced_comp) || 0,
					},
				],
				summary: [
					{
						total: {
							value:
								res?.total_pipeline?.Marketing_Influenced?.block_data[2]?.value,
							change:
								twoDecimal(
									res?.total_pipeline?.Marketing_Influenced?.block_data[2]
										?.Comparison_data
								) || 0,
						},
						roi: {
							value:
								res?.total_pipeline?.Marketing_Influenced?.block_data[1]?.value,
							change:
								twoDecimal(
									res?.total_pipeline?.Marketing_Influenced?.block_data[1]
										?.Comparison_data
								) || 0,
						},
						oppts: {
							value:
								res?.total_pipeline?.Marketing_Influenced?.block_data[0]?.value,
							change:
								twoDecimal(
									res?.total_pipeline?.Marketing_Influenced?.block_data[0]
										?.Comparison_data
								) || 0,
						},
						cpo: {
							value: res?.total_pipeline?.CPO?.block_data[0]?.value,
							change: res?.total_pipeline?.mi_cpo_comp || 0,
						},
						options: {
							label: 'Marketing Influenced',
							color: '#9149FF',
						},
					},
					{
						total: {
							value:
								res?.total_pipeline?.Marketing_Sourced?.block_data[2]?.value,
							change:
								twoDecimal(
									res?.total_pipeline?.Marketing_Sourced?.block_data[2]
										?.Comparison_data
								) || 0,
						},
						roi: {
							value:
								res?.total_pipeline?.Marketing_Sourced?.block_data[1]?.value,
							change:
								twoDecimal(
									res?.total_pipeline?.Marketing_Sourced?.block_data[1]
										?.Comparison_data
								) || 0,
						},
						oppts: {
							value:
								res?.total_pipeline?.Marketing_Sourced?.block_data[0]?.value,
							change:
								twoDecimal(
									res?.total_pipeline?.Marketing_Sourced?.block_data[0]
										?.Comparison_data
								) || 0,
						},
						cpo: {
							value: res?.total_pipeline?.ms_cpo,
							change: res?.total_pipeline?.ms_cpo_comp || 0,
						},
						options: {
							label: 'Marketing Sourced',
							color: '#133463',
						},
					},
				],
			},
		};
	}

	switch (action.type) {
		case GLOBAL_FILTER_DATA:
			return {
				...state,
				global_filter_data: action?.data,
			};
		case SET_CMO_REQUEST_DATA:
			requestData = action.data;
			return { ...requestData };

		case SET_CMO_CLOSED_WON_DATA:
			state['cmo_closed_won']['roi_cards_data'] = getClosedWonData(
				action?.data
			);
			state['cmo_closed_won']['cards_data'] = getCardsData(action?.data);
			state['average_sale_cycle_data'] = getAverageSaleCycleData(action?.data);
			state['average_deal_cycle_data'] = getAverageDealCycleData(action?.data);
			state['cmo_closed_won']['closed_won_card_data'] = getClosedWonCardData(
				//   where is what i need answer for ----------------------------------------
				action?.data
			);
			return { ...state };

		case SET_CMO_PIPELINE_API_DATA:
			return {
				...state,
				cmo_pipeline_data: {
					...state.cmo_pipeline_data, // Copy existing cmo_pipeline_data
					roi_cards_data: getCmoPipelineData(action.data), // Update roi_cards_data
					donut_data: getCmoPipleineDonutData(action.data), // Update donut_data
					box_data: getCmoPipelineBoxData(action.data), // Update box_data
				},
			};

		case SET_CMO_INSIGHTS_TABLE_DATA:
			state['cmo_insights_table_data'] = action.data;
			return { ...state };
		case PDF_CLICKED:
			return { ...state, isPdfClicked: action?.data };
		case PATH_SAME_DRILL_DOWN:
			return { ...state, pathSameDrillDown: action?.data };
		case DRILL_DOWN_CSV_HIDE_OPTION:
			return { ...state, drillDownCsvHideValue: action?.data };
		case PDF_COLUMNS:
			return {
				...state,
				selectedColumnForPdf: {
					key: action?.data?.key,
					selectedArray: action?.data?.selectedArray,
				},
			};
		case ROWS_COUNT_PDF:
			return { ...state, rowsCountForPdf: action?.data };
		case TABLEDATA_FOR_PDF:
			return { ...state, isTableDataForPdf: action?.data };
		case SET_CMO_GRAPH_DATA:
			state['cmo_graph_data'] = getCmoGraphData(
				action.data.response,
				action.data.startDate,
				action.data.endDate,
				action.data.matrices
			);
			return { ...state };
		case SET_CMO_REQUEST_BODY:
			switch (action?.type) {
				case 'date_range':
					state['cmo_request_body']['date_range'] = action.data;
					return { ...state };
				default:
					return { ...state };
			}
			return { ...state };
		case SET_CMO_BOOKMARK_REQUEST_BODY_DATA:
			state['cmo_bookmark_request_body'] = action.data;
			return { ...state };
		case SET_CMO_METRIC_VALUE:
			state['cmo_metric_value'] = action.data;
			return { ...state };
		case SET_CMO_DIMENSION_VALUE:
			state['cmo_dimension_value'] = action.data;
			return { ...state };
		case RESET_TO_INTIAL_STATE:
			state = {
				cmo_closed_won: {
					roi_cards_data: null,
					cards_data: null,
					closed_won_card_data: null,
				},
				average_sale_cycle_data: null,
				average_deal_cycle_data: null,
				cmo_pipeline_data: {
					roi_cards_data: null,
					donut_data: null,
					box_data: null,
				},
				cmo_insights_table_data: null,
				cmo_request_body: {
					date_range: InsightsData.getDefaultRange(),
					filter_data: InsightsData.getDefaultCMOFilters(),
				},
				cmo_bookmark_request_body: null,
				// cmo_metric_value: 'spend',
				// cmo_dimension_value: 'channel',
			};
			return { ...state };
	}
};
